<template>
  <div :loading="entityLoading" style="background-color: #ffff !important" v-if="isDialogActive">
    <el-row  class="dialog-template-preview">
      <PreviewFormbuilderTemplate
        v-if="getCurrentStep && !refresh"
        :templateData="getCurrentStep.templateInfo"
        :entityDataId="entityDataId"
        :workflowDetailsId="workflowDetailsId"
        :isExecute="isExecute"
        :isFormFormbuilder="true"
        :isEntityExecute="true"
        :form="currentTemplateData"
        :buttonText="buttonText"
        :entityId="getEntityId"
        :currentEntity="currentEntity"
        :currentStep="getCurrentStep"
        type="FORM_BUILDER_ENTITY_TEMPLATES"
        :entityData="entityData"
      />
    </el-row>
    <el-row>
      <!-- <el-col :offset="20"> -->
      <div style="float: right" v-if="fromSenderSide">
        <div v-if="isExecute">
          <!-- <el-button v-if="checkHasPrev" plain type="primary">
            Previous
          </el-button> -->
          <el-button
            v-if="getIsFormbuilderMode && isNotSentformbuilder"
            style="margin-right: 165px"
            icon="el-icon-s-promotion"
            @click="gotoNextEntityTemplate(false, true)"
          >
            Send Form builder
          </el-button>
          <el-button
            type="primary"
            @click="gotoNextEntityTemplate(false)"
            :disabled="repeatableDataDisabled"
          >
            {{ checkHasNext ? "Update & Next" : "Update" }}
          </el-button>
        </div>
        <div v-else>
          <el-button
            v-if="!isExecute&&checkApprovalEdit && (!getUserFormbuilderData || getUserFormbuilderData.approval_status !== 'REJECTED') && checkReceiverPermissions('edit')"
            plain
            type="primary"
            @click="openEditMode"
          >
            Edit
          </el-button>
          <!-- <el-button
            type="primary"
            v-if="checkHasNext"
            @click="gotoNextTemplate"
          >
            Next
          </el-button> -->
        </div>
      </div>
      <!-- </el-col> -->
    </el-row>
    <div v-if="checkIsRepeatable" class="repeatable-status">
      <div style="text-align: center" class="mt-1">
        <el-button
          @click="preRepeatableData"
          size="mini"
          v-if="this.repeatableData && this.repeatableData.length"
        >
          Prev
        </el-button>
        <el-button
          size="mini"
          @click="nextRepeatableData"
          v-if="this.repeatableData && this.repeatableData.length"
        >
          Next
        </el-button>
        <el-button
          v-if="
            (this.repeatableData.length + 1 < getMaxNoOfRecords ||
              this.repeatableData.length == 0) &&
            currentRepeatableIndex == -1 &&
            isExecute
          "
          size="mini"
          type="primary"
          :disabled="checkAllFieldsFillted || addNewRepeatableDisable"
          @click="gotoNextEntityTemplate(true)"
          :loading="entityLoading"
        >
          Add
          {{ getCurrentTemplateName }}
          {{ repeatableData.length + 1 }}
        </el-button>
      </div>
    </div>
    <div class="document-status" v-if="!fromSenderSide">
      <div class="mt-1" v-if="step && step.type == 'ENTITY'">
        <div class="template-name ml-2" v-if="checkIsRepeatable">
          {{ getCurrentTemplateName }}
          {{
            currentRepeatableIndex > -1
              ? currentRepeatableIndex + 1
              : repeatableData.length + 1
          }}
        </div>
        <div class="template-name ml-2" v-else>
          {{ getCurrentTemplateName }}
        </div>
        <div style="text-align: center">
          <el-button
            @click="gotoPrevEntityTemplate"
            v-if="currentTemplateIndex"
            :loading="entityLoading"
            :disabled="checkAllFieldsFillted"
            type="primary"
            plain
          >
            Previous
          </el-button>
          <el-button
            @click="gotoPreStep"
            v-else-if="!currentTemplateIndex && this.currentStepIndex"
            :loading="entityLoading"
            :disabled="checkAllFieldsFillted"
            type="primary"
            plain
          >
            Previous
          </el-button>
          <el-button
            @click="gotoNextEntityTemplate(false)"
            :loading="entityLoading"
            type="primary"
            :disabled="repeatableDataDisabled"
          >
            {{
              this.getAllSteps.length == parseInt(this.currentTemplateIndex) + 1
                ? "Finish"
                : "Next"
            }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <div :loading="entityLoading" style="background-color: #ffff !important" v-else>
    <el-row>
      <!-- <el-col :offset="20"> -->
      <div style="float: right" v-if="fromSenderSide">
        <div v-if="isExecute">
          <!-- <el-button v-if="checkHasPrev" plain type="primary">
            Previous
          </el-button> -->
          <el-button
            v-if="getIsFormbuilderMode && isNotSentformbuilder"
            style="margin-right: 165px"
            icon="el-icon-s-promotion"
            @click="gotoNextEntityTemplate(false, true)"
          >
            Send Form builder
          </el-button>
          <el-button
            type="primary"
            @click="gotoNextEntityTemplate(false)"
            :disabled="repeatableDataDisabled"
          >
            {{ checkHasNext ? "Update & Next" : "Update" }}
          </el-button>
        </div>
        <div v-else>
          <el-button
            v-if="!isExecute &&  IframePermissions('EDIT','FormBuilderUserDate') && checkReceiverPermissions('edit')"
            type="primary"
            @click="openEditMode"
          >
            Edit
          </el-button>
          <!-- <el-button
            type="primary"
            v-if="checkHasNext"
            @click="gotoNextTemplate"
          >
            Next
          </el-button> -->
        </div>
      </div>
      <!-- </el-col> -->
    </el-row>
    <el-row class="form-builder-template-view">
      <PreviewFormbuilderTemplate
        v-if="getCurrentStep && !refresh"
        :templateData="getCurrentStep.templateInfo"
        :entityDataId="entityDataId"
        :workflowDetailsId="workflowDetailsId"
        :isExecute="isExecute"
        :isFormFormbuilder="true"
        :isEntityExecute="true"
        :form="currentTemplateData"
        :buttonText="buttonText"
        :entityId="getEntityId"
        :currentEntity="currentEntity"
        :currentStep="getCurrentStep"
        type="FORM_BUILDER_ENTITY_TEMPLATES"
        :entityData="entityData"
      />
    </el-row>
    <div v-if="checkIsRepeatable" class="repeatable-status">
      <div style="text-align: center" class="mt-1">
        <el-button
          @click="preRepeatableData"
          size="mini"
          v-if="this.repeatableData && this.repeatableData.length"
        >
          Prev
        </el-button>
        <el-button
          size="mini"
          @click="nextRepeatableData"
          v-if="this.repeatableData && this.repeatableData.length"
        >
          Next
        </el-button>
        <el-button
          v-if="
            (this.repeatableData.length + 1 < getMaxNoOfRecords ||
              this.repeatableData.length == 0) &&
            currentRepeatableIndex == -1 &&
            isExecute
          "
          size="mini"
          type="primary"
          :disabled="checkAllFieldsFillted || addNewRepeatableDisable"
          @click="gotoNextEntityTemplate(true)"
          :loading="entityLoading"
        >
          Add
          {{ getCurrentTemplateName }}
          {{ repeatableData.length + 1 }}
        </el-button>
      </div>
    </div>
    <div class="document-status" v-if="!fromSenderSide">
      <div class="mt-1" v-if="step && step.type == 'ENTITY'">
        <div class="template-name ml-2" v-if="checkIsRepeatable">
          {{ getCurrentTemplateName }}
          {{
            currentRepeatableIndex > -1
              ? currentRepeatableIndex + 1
              : repeatableData.length + 1
          }}
        </div>
        <div class="template-name ml-2" v-else>
          {{ getCurrentTemplateName }}
        </div>
        <div style="text-align: center">
          <el-button
            @click="gotoPrevEntityTemplate"
            v-if="currentTemplateIndex"
            :loading="entityLoading"
            :disabled="checkAllFieldsFillted"
            type="primary"
            plain
          >
            Previous
          </el-button>
          <el-button
            @click="gotoPreStep"
            v-else-if="!currentTemplateIndex && this.currentStepIndex"
            :loading="entityLoading"
            :disabled="checkAllFieldsFillted"
            type="primary"
            plain
          >
            Previous
          </el-button>
          <el-button
            @click="gotoNextEntityTemplate(false)"
            :loading="entityLoading"
            type="primary"
            :disabled="repeatableDataDisabled"
          >
            {{
              this.getAllSteps.length == parseInt(this.currentTemplateIndex) + 1
                ? "Finish"
                : "Next"
            }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { makeSlug } from "../../helpers/appHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import NavigationHelper from "@/mixins/navigationHelper";
export default {
  mixins: [FormbuilderHelper, userPermissionsHelper, entityRelationshipMixin, NavigationHelper],
  components: {
    PreviewFormbuilderTemplate: () =>
      import("../templates/previewFormbuilderTemplate.vue"),
  },
  data() {
    return {
      isDialogActive: false,
      entityLoading: false,
      currentEntity: null,
      refresh: false,
      currentTemplateIndex: 0,
      entityData: {},
      currentTemplateData: {},
      relationshipData: {},
      userEntityDataId: "",
      currentEntityTemplates: [],
      unAssignedFieldDetails: [],
      repeatableData: [],
      currentRepeatableIndex: -1,
      addingNewData: false,
      allStepsCompleted: false,
    };
  },
  props: [
    "step",
    "workflowDetailsId",
    "templateData",
    "isExecute",
    "form",
    "buttonText",
    "currentStepIndex",
    "fromSenderSide",
    "existedEntityDataId",
    "isNotSentformbuilder",
    "getUserFormbuilderData"
  ],
  mounted() {
    if (this.step && this.step.entity) {
      this.getNecessaryInfo();
    }
    if (this.fromSenderSide) {
      let index = this.$route.query?.temp_id;
      if (!index && index != 0 && index != "0") {
        index = 0;
      }
      this.currentTemplateIndex = index;
    }
    if(this.$route.query.dialogVisible == "true"){
      this.isDialogActive = true;
    }
    else{
      this.isDialogActive = false;
    }
  },
  computed: {
    ...mapGetters("entities", ["getEntityById", "getEntityPrimaryData"]),
    ...mapGetters("templatesData", [
      "getTemplatesDataUpdateStatus",
      "getUserTemplateDataUpdateStatus",
      "getUserTemplateDataUpdateErrors",
      "getRepeatableTemplateIds",
      "getUploadedFileData",
      "getFileUploadURL",
      "getFileUploadRefId",
      "getDocumentUploadStatus",
      "getDocumentUploadStatusUpdated",
      "getUploadedFileData",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getParentEntityRelatiponshipData",
      "getEntityAllRelationshipData",
      "getEntityRelationshipDataAssignStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("formBuilders", [
      "getFormBuilder",
      "getFormBuilderFillingInfo",
    ]),
    // ...mapGetters("formbuilderData", ["getUserFormbuilderData"]),
    ...mapGetters("iFrame",["getIframeUrlInfo"]),
    getCurrentStep() {
      return this.getAllSteps[this.currentTemplateIndex];
    },
    getAllSteps() {
      return this.currentEntityTemplates || [];
    },
    getCurrentFormStep() {
      return this.step;
    },
    getCurrentEntityDataId() {
      return this.entityDataId;
    },
    getEntityId() {
      return this.step.entity._id || this.step.entity;
    },
    checkApprovalEdit(){
      let q =this.$route.query;
      if(q.up == false || q.up == 'false'){
        return false
      }
      return true
    },
    checkAllFieldsFillted() {
      if (this.getCurrentStep?.templateInfo?.sections[0]?.fields) {
        let requiredFields =
          this.getCurrentStep?.templateInfo?.sections[0]?.fields.filter(
            (el) => {
              if (this.fieldFilledByCurrentUser(el.filled_by)) {
                if (el.inputType == "WEEKDAYS") {
                  return this.checkIsRequired(el);
                } else if (el.inputType == "CHECKBOX_GROUP") {
                  return this.checkIsMinValue(el);
                } else {
                  return el?.validations?.required;
                }
              }
            }
          );
        if (requiredFields && requiredFields.length) {
          let emptyField = requiredFields.find((e) => {
            if (e.inputType == "WEEKDAYS") {
              return this.checkIsNotFilled(e, this.currentTemplateData);
            } else if (e.inputType == "CHECKBOX_GROUP") {
              return this.checkIsCheckBoxFilledOrNot(
                e,
                this.currentTemplateData
              );
            } else {
              if (e.inputType == "NUMBER") {
                return (
                  (typeof this.currentTemplateData[e.key] != "number" &&
                    this.currentTemplateData[e.key] != 0) ||
                  isNaN(this.currentTemplateData[e.key])
                );
              } else {
                return (
                  Object.keys(this.currentTemplateData).indexOf(e.key) === -1 ||
                  this.currentTemplateData[e.key] == "" ||
                  this.currentTemplateData[e.key] == null ||
                  this.currentTemplateData[e.key] == undefined ||
                  (typeof this.currentTemplateData[e.key] != "object" &&
                    !this.currentTemplateData[e.key].length &&
                    (e.inputType == "DATE" || e.inputType == "DATE_TIME"))
                );
              }
            }
          });
          if (emptyField) {
            return true;
          }
        } else {
          let onlyRequiredFields =
            this.getCurrentStep?.templateInfo?.sections[0]?.fields.filter(
              (el) =>
                !el?.validations?.required &&
                el.type != "CONTENT" &&
                this.fieldFilledByCurrentUser(el.filled_by)
            );
          let filledField = onlyRequiredFields.find(
            (e) =>
              this.currentTemplateData[e.key] != "" &&
              this.currentTemplateData[e.key] != null &&
              this.currentTemplateData[e.key] != undefined
          );
          if (onlyRequiredFields && onlyRequiredFields.length && !filledField) {
            return false;
          }
          return false;
        }
      }
      return false;
    },
    checkHasPrev() {
      return this.currentTemplateIndex != 0 || this.currentStepIndex != 0;
    },
    entityDataId() {
      if (this.fromSenderSide && this.existedEntityDataId) {
        return this.existedEntityDataId;
      } else if (
        this.getFormBuilder.form_builders_owner == "ENTITY" &&
        this.step?.settings?.isLoginEntity &&
        this.getAuthenticatedUser?.account_data_id
      ) {
        return this.getAuthenticatedUser.account_data_id;
      } else if (this.step?.settings?.applyRelationship) {
        let currentStep = (this.getEntityAllRelationshipData || []).find(
          (e) =>
            e.relationship_id == this.step?.entity?._id ||
            e.relationship_id == this.step.entity
        );
        if (
          currentStep &&
          (currentStep[currentStep?.relation_type] == "ONE" ||
            this.currentTemplateIndex > 0) &&
          currentStep?.entity_data_ids &&
          currentStep.entity_data_ids.length
        ) {
          return currentStep?.entity_data_ids &&
            currentStep.entity_data_ids.length
            ? currentStep.entity_data_ids[
                currentStep.entity_data_ids.length - 1
              ]
            : "";
        }
      } else if (this.userEntityDataId) {
        return this.userEntityDataId;
      }
      const formbuilderTemplateData = this.getFormBuilder?.steps.find((e) => {
        let id = e.entity && e.entity._id ? e.entity._id : e.entity;
        if (id == this.step.entity._id || id == this.step.entity) {
          return e;
        }
      });
      const currenctTemplateData = (
        this.getUserFormbuilderData?.formbuilder_steps_data || []
      ).find((e) => {
        let id = e.entity_id && e.entity_id._id ? e.entity_id._id : e.entity_id;
        if (id == this.step.entity._id || id == this.step.entity) {
          return e;
        }
      });
      if (this.$route.params?.formbuilder_data_id && currenctTemplateData) {
        return (
          currenctTemplateData?.entity_data_id ||
          currenctTemplateData?.entityDataId
        );
      }
      return (
        formbuilderTemplateData?.entityDataId ||
        formbuilderTemplateData?.entity_data_id ||
        this.step.entityDataId
      );
    },
    checkHasNext() {
      return (
        this.step.has_next ||
        (this.step?.entity?.templates &&
          this.step.entity.templates.length >
            parseInt(this.currentTemplateIndex) + 1)
      );
    },
    currentStepRelationship() {
      return (this.getEntityAllRelationshipData || []).find(
        (e) =>
          e.relationship_id == this.step?.entity?._id ||
          e.relationship_id == this.step.entity
      );
    },
    getCurrentTemplateName() {
      if (this.getCurrentStep?.templateInfo?.name) {
        return this.getCurrentStep.templateInfo.name;
      }
      return "Data";
    },
    getCurrentTemplateFields() {
      return this.getTemplateFields(this.getCurrentStep?.templateInfo ?? null);
    },
    checkIsRepeatable() {
      return this.getCurrentStep?.is_repeatable;
    },
    getMinNoOfRecords() {
      if (
        this.getCurrentStep?.min_linked_field &&
        this.getFormBuilderFillingInfo
      ) {
        let [stepId, temp_id, key] =
          this.getCurrentStep?.min_linked_field.split("#");
        if (
          this.getFormBuilderFillingInfo[stepId] &&
          this.getFormBuilderFillingInfo[stepId][temp_id] &&
          this.getFormBuilderFillingInfo[stepId][temp_id][key]
        ) {
          let min = parseInt(
            this.getFormBuilderFillingInfo[stepId][temp_id][key]
          );
          return min;
        } else if (
          this.getFormBuilderFillingInfo[this.currentStepIndex] &&
          this.getFormBuilderFillingInfo[this.currentStepIndex][temp_id] &&
          this.getFormBuilderFillingInfo[this.currentStepIndex][temp_id][key]
        ) {
          let min = parseInt(
            this.getFormBuilderFillingInfo[this.currentStepIndex][temp_id][key]
          );
          return min;
        }
        return 0;
      } else if (this.getCurrentStep?.min_records) {
        return parseInt(this.getCurrentStep?.min_records);
      }
      return 0;
    },
    getMaxNoOfRecords() {
      if (
        this.getCurrentStep?.max_linked_field &&
        this.getFormBuilderFillingInfo
      ) {
        let [stepId, temp_id, key] =
          this.getCurrentStep.max_linked_field.split("#");
        if (
          this.getFormBuilderFillingInfo[stepId] &&
          this.getFormBuilderFillingInfo[stepId][temp_id] &&
          this.getFormBuilderFillingInfo[stepId][temp_id][key]
        ) {
          let max = parseInt(
            this.getFormBuilderFillingInfo[stepId][temp_id][key]
          );
          if (max < this.getMinNoOfRecords) {
            return this.getMinNoOfRecords;
          }
          return max;
        } else if (
          this.getFormBuilderFillingInfo[this.currentStepIndex] &&
          this.getFormBuilderFillingInfo[this.currentStepIndex][temp_id] &&
          this.getFormBuilderFillingInfo[this.currentStepIndex][temp_id][key]
        ) {
          let min = parseInt(
            this.getFormBuilderFillingInfo[this.currentStepIndex][temp_id][key]
          );
          return min;
        }
        return 1000;
      } else if (this.getCurrentStep?.max_records) {
        return parseInt(this.getCurrentStep.max_records);
      }
      return 1000;
    },
    addNewRepeatableDisable() {
      if (
        this.checkIsRepeatable &&
        (this.repeatableData.length || 0) + 1 >= this.getMaxNoOfRecords
      ) {
        return true;
      }
      return false;
    },
    repeatableDataDisabled() {
      if (!this.isExecute) {
        return false;
      }
      if (this.checkIsRepeatable) {
        if (this.getMinNoOfRecords == 0) {
          return false;
        } else if (
          this.repeatableData &&
          this.repeatableData.length + 1 < this.getMinNoOfRecords
        ) {
          return true;
        }
        return this.checkAllFieldsFillted;
      }
      return this.checkAllFieldsFillted;
    },
    getIsFormbuilderMode() {
      return (
        this.$route.name == "FormbuilderViewStep" ||
        this.$route.name == "FormbuilderEditStep" ||
        this.$route.name == "FormbuilderEditStepSSO_External" ||
        this.$route.name == "FormbuilderViewStepSSO_External" ||
        this.$route.name == "ApplicationUserFormbuilderViewStep" ||
        this.$route.name == "ApplicationUserFormbuilderEditStep"
      );
    },
    isApplicationUser() {
      if (
        [
          "ApplicationUserFormbuilderView",
          "ApplicationUserFormbuilderViewStep",
          "ApplicationUserFormbuilderEdit",
          "ApplicationUserFormbuilderEditStep",
        ].indexOf(this.$route.name) !== -1
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.$bus.$on("pay-button-action-form-builder-entity", (data) => {
      this.gotoNextEntityTemplate(false, false, true, data);
      this.$bus.$off("pay-button-action-form-builder-entity");
    });
  },
  watch: {
    "$route.params.template_code"() {
      this.fetchEntityData(this.entityDataId);
    },
    entityDataId(value, oldValue) {
      if (value !== oldValue) {
        this.fetchEntityData(this.entityDataId);
      }
    },
    getEntityAllRelationshipData() {
      this.setEntityRelationshipData();
    },
  },
  methods: {
    checkReceiverPermissions(access) {
      // If form is approved, whether need to give permissions to view, edit, delete submission for receiver(application user or company user)
      if ((this.$route?.name == 'applicationFormBuilderUserData' || this.$route?.query?.routeType == 'FB'
        || this.$route?.name == 'ApplicationUserFormbuilderViewStep') && this.getUserFormbuilderData?.approval_status == 'APPROVED') 
      {
        if (this.getFormBuilder?.approval_receiver_permissions) {
          let permissions = this.getFormBuilder.approval_receiver_permissions;
          if (access == 'view' && permissions.view == true) {
            return true;
          } else if (access == 'edit' && permissions.edit == true) {
            return true;
          } else if (access == 'delete' && permissions.delete == true) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    checkIsMinValue(data) {
      if (data && data.min_selection > 0) {
        return true;
      } else {
        return false;
      }
    },
    checkIsRequired(data) {
      if (data.allow_multiple) {
        if (data.min_value > 0) {
          return true;
        }
        return false;
      }
      return data?.validations?.required ? true : false;
    },
    checkIsNotFilled(field, data) {
      if (field.allow_multiple) {
        if (field.min_value > 0) {
          if (
            data &&
            data[field.key] &&
            typeof data[field.key] == "object" &&
            data[field.key].length >= field.min_value
          ) {
            return false;
          }
          return true;
        }
        return false;
      } else {
        if (data && data[field.key]) {
          return false;
        }
        return true;
      }
    },
    checkIsCheckBoxFilledOrNot(field, data) {
      if (field && field.min_selection > 0) {
        if (
          data &&
          data[field.key] &&
          typeof data[field.key] == "object" &&
          data[field.key].length >= field.min_selection
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    getTemplateFields(data) {
      let fields = [];
      (data && data.sections ? data.sections : []).forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    nextRepeatableData() {
      if (
        this.currentRepeatableIndex > -1 &&
        this.repeatableData[this.currentRepeatableIndex]
      ) {
        this.repeatableData[this.currentRepeatableIndex] = {
          ...this.currentTemplateData,
        };
      }
      if (
        this.currentRepeatableIndex + 1 >= this.repeatableData.length &&
        this.repeatableData.length < this.getMaxNoOfRecords &&
        this.isExecute
      ) {
        this.currentTemplateData = {};
        this.addingNewData = true;
        this.currentRepeatableIndex = -1;
      } else if (
        this.isExecute &&
        this.currentRepeatableIndex != -1 &&
        this.currentRepeatableIndex + 1 < this.repeatableData.length &&
        this.repeatableData[this.currentRepeatableIndex + 1]
      ) {
        this.addingNewData = false;
        this.currentRepeatableIndex = this.currentRepeatableIndex + 1;
        let formData = {
          ...this.repeatableData[this.currentRepeatableIndex],
        };
        this.setFormValues(formData);
      } else if (!this.isExecute) {
        let formData;
        if (
          this.currentRepeatableIndex > -1 &&
          this.currentRepeatableIndex + 1 < this.repeatableData.length
        ) {
          this.currentRepeatableIndex = this.currentRepeatableIndex + 1;
          formData = {
            ...this.repeatableData[this.currentRepeatableIndex],
          };
        } else {
          this.currentRepeatableIndex = 0;
          formData = {
            ...this.repeatableData[this.currentRepeatableIndex],
          };
        }
        this.setFormValues(formData);
      } else {
        this.$message({
          message: "Last data of the list. Can't move forward ",
          type: "warning",
        });
      }
    },
    preRepeatableData() {
      if (
        this.currentRepeatableIndex > -1 &&
        this.repeatableData[this.currentRepeatableIndex]
      ) {
        this.repeatableData[this.currentRepeatableIndex] = {
          ...this.currentTemplateData,
        };
      }
      if (
        (this.currentRepeatableIndex > 0 || this.addingNewData) &&
        this.isExecute
      ) {
        this.currentRepeatableIndex =
          this.currentRepeatableIndex > -1
            ? this.currentRepeatableIndex - 1
            : this.repeatableData.length - 1;
        this.addingNewData = false;
        this.currentTemplateData = {};
        let formData = { ...this.repeatableData[this.currentRepeatableIndex] };
        this.setFormValues(formData);
      } else if (!this.isExecute) {
        let formData;
        if (this.currentRepeatableIndex > 0) {
          this.currentRepeatableIndex = this.currentRepeatableIndex - 1;
          formData = {
            ...this.repeatableData[this.currentRepeatableIndex],
          };
        } else {
          this.currentRepeatableIndex = this.repeatableData.length - 1;
          formData = {
            ...this.repeatableData[this.currentRepeatableIndex],
          };
        }
        this.setFormValues(formData);
      } else {
        this.$message({
          message: "Starting of the list. Can't go previous ",
          type: "warning",
        });
      }
    },
    setFormValues(formData) {
      this.getCurrentTemplateFields.forEach((e) => {
        this.$set(this.currentTemplateData, e.key, formData[e.key]);
        if (e.input_type == "QUESTION" && e.has_other_option) {
          this.$set(
            this.currentTemplateData,
            `${e.key}/otheroption`,
            formData[e.key + "/otheroption"]
              ? formData[e.key + "/otheroption"]
              : ""
          );
        }
        if (e.input_type === "PHONE_COUNTRY_CODE") {
          this.$set(
            this.currentTemplateData,
            e.key + "_code",
            formData && formData[e.key + "_code"]
              ? formData[e.key + "_code"]
              : {}
          );
        }
        if (e.input_type === "CURRENCY") {
          this.$set(
            this.currentTemplateData,
            e.key + "_currency",
            e.validations.currency
          );
        }if (e.input_type === "ZIP_CODE") {
          this.$set(
            this.currentTemplateData,
            e.key + "_zipcode",
            e.validations.currency
          );
        }
        if (e.inputType === "SINGLE_LINE_TEXT" && e.input_type === "MASKED") {
          this.$set(
            this.form,
            e.key + "_masked",
            formData && formData[e.key + "_masked"]
              ? formData[e.key + "_masked"]
              : ""
          );
        }
        if (e.input_type === "CHECKBOX_GROUP") {
          this.$set(this.currentTemplateData, e.key, formData[e.key]);
        }
      });
      if (formData["indexKey"]) {
        this.$set(this.currentTemplateData, "indexKey", formData["indexKey"]);
      }
    },
    openEditMode() {
      if (this.isApplicationUser) {
        this.$router.push({
          name: "ApplicationUserFormbuilderEditStep",
          params: this.$route.params,
          query: this.$route.query,
        });
      } else {
        let Url_name ='';
        if (this.getIframeUrlInfo != null) {
          Url_name="FormbuilderEditStepSSO_External"
        }
        else{
          Url_name="FormbuilderEditStep"
        }
        this.$router.push({
          name: Url_name,
          params: this.$route.params,
          query: this.$route.query,
        });
      }
    },
    updateStepEntityData(data) {
      if (
        data?.entityDataId &&
        typeof data?.index == "number" &&
        this.getFormBuilder.steps[data.index]
      ) {
        let formbuilderData = { ...this.getFormBuilder };
        let step = formbuilderData.steps[data.index];
        step.entityDataId = data.entityDataId;
        formbuilderData.steps[data.index] = { ...step };
        this.$store.commit(
          "formBuilders/setFormBuilder",
          {},
          {
            root: true,
          }
        );
        this.$store.commit("formBuilders/setFormBuilder", formbuilderData, {
          root: true,
        });
      }
    },
    gotoPreStep() {
      if (
        parseInt(this.currentTemplateIndex) == 0 &&
        parseInt(this.currentStepIndex) > 0
      ) {
        this.$emit("prevStep", {});
      } else {
        this.$emit("movePrevStep", {});
      }
    },
    gotoPrevTemplate() {
      if (
        this.step?.entity?.templates?.length <=
        parseInt(this.currentTemplateIndex) + 1
      ) {
        bus.$emit("next-step", {});
      } else {
        this.$emit("moveNextStep", {});
      }
    },
    gotoNextTemplate() {
      if (
        this.step?.entity?.templates?.length <=
        parseInt(this.currentTemplateIndex) + 1
      ) {
        bus.$emit("next-step", {});
      } else {
        this.$emit("moveNextStep", {});
      }
    },
    async fetchEntityData(id) {
      await this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
        id: id,
        includeTemplateData: true,
      });
      this.entityData = {};
      if (
        this.getEntityPrimaryData &&
        (this.getEntityPrimaryData.entity_id == this.step.entity._id ||
          this.getEntityPrimaryData.entity_id == this.step.entity)
      ) {
        this.entityData = this.getEntityPrimaryData;
        if (this.entityData.templateData) {
          //fetch entity data
          this.setCurrentTemplateData();
        }
      }
    },
    async prepareFormData(form) {
      // this.entityLoading = true;
      //Update parent entity data by entity variable - mixin method
      await this.updateParentDataByEntityVariable(
        this.getCurrentTemplateFields,
        form,
        {
          company_id: this.currentEntity.company_id,
          user_id: this.currentEntity.created_by,
        }
      );
      for (let [key, value] of Object.entries(form)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          let path = await this.uploadFile(value.files);
          if (form[key] && form[key].files) {
            form[key].files = value?.files;
          }
          if (form[key] && form[key].path) {
            form[key].path = path;
            if (form[key].name) {
              form[key].name = value?.file?.name;
            }
          } else {
            form[key] = {
              path: path,
              name: value?.file?.name,
              files: value?.files,
            };
          }
          form[key].new = false;
          form[key].files = value?.files;
        }
      }
      // this.entityLoading = false;

      if (this.checkIsRepeatable) {
        if (this.getMinNoOfRecords == 0 && this.repeatableData.length == 0) {
          return {
            [this.getCurrentTemplateName.toLowerCase().split(" ").join("_")]:
              [],
          };
        } else {
          await this.$store.dispatch(
            "templatesData/fetchRepeatableTemplateIds",
            this.getMaxNoOfRecords + 10
          );
          if (
            this.currentRepeatableIndex > -1 &&
            this.repeatableData[this.currentRepeatableIndex]
          ) {
            this.repeatableData[this.currentRepeatableIndex] = {
              ...{
                indexKey:
                  this.getRepeatableTemplateIds[this.currentRepeatableIndex],
              },
              ...form,
            };
          } else {
            this.repeatableData.push({
              ...{
                indexKey:
                  this.getRepeatableTemplateIds[this.repeatableData.length],
              },
              ...form,
            });
          }
          return {
            [this.getCurrentTemplateName.toLowerCase().split(" ").join("_")]:
              this.repeatableData,
          };
        }
      } else {
        return form;
      }
    },
    async uploadFile(file) {
      file.forEach(async (el) => {
        await this.getFileUploadUrl(el);
        await this.uploadSelectedDocument(el.raw);
        if (this.getUploadedFileData) {
          return (
            this.getUploadedFileData.slug + "/" + this.getUploadedFileData.key
          );
        } else {
          return "";
        }
      });
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );

      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          console.log("fileuploaded");
        }
        //  else {
        //   this.$message("Sorry ! Error while file uploading");
        // }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    async gotoNextEntityTemplate(
      checkRepeatable,
      sendForm = false,
      isFromPayButton = false,
      payButtonData = {}
    ) {
      if (
        this.step?.entity?.entity_type == "INDIVIDUAL" &&
        this.getCurrentStep?.templateInfo?.slug == "contactdetails" &&
        this.getCurrentStep?.templateInfo?.type == "STANDARD"
      ) {
        let PhoneRe =
          /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
        let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (
          !this.currentTemplateData.email ||
          (this.currentTemplateData?.email &&
            !re.test(this.currentTemplateData.email))
        ) {
          this.$notify.error({
            title: "Error",
            message: "Invalid email!",
          });
          return;
        } else if (
          this.currentTemplateData?.phone_number &&
          !PhoneRe.test(this.currentTemplateData.phone_number.replace(/\s/g, ''))
        ) {
          this.$notify.error({
            title: "Error",
            message: "Invalid Phone Number!",
          });
          return;
        }
      }
      let location = window.location.href;
      this.entityLoading = true;
      let form = await this.prepareFormData(this.currentTemplateData);
      let params = {
        ...{
          template_data: form,
          template_id: this.getCurrentStep.template_id,
          company_id: this.currentEntity.company_id,
          user_id: this.currentEntity.created_by,
          entity_id: this.step.entity._id || this.step.entity,
          entity_data_id: this.entityDataId
            ? this.entityDataId
            : this.getCurrentEntityDataId
            ? this.getCurrentEntityDataId
            : "newData",
        },
      };
      await this.$store.dispatch(
        "templatesData/updateUserTemplateData",
        params
      );
      if (this.getTemplatesDataUpdateStatus) {
        this.setFormbuilderFormData(
          form,
          this.currentStepIndex,
          this.getCurrentStep.template_id
        );
        let newCreated = false;
        let dataId = this.entityDataId
          ? this.entityDataId
          : this.getCurrentEntityDataId
          ? this.getCurrentEntityDataId
          : this.getUserTemplateDataUpdateStatus?._id;

        if (dataId) {
          if (isFromPayButton && payButtonData && payButtonData.action) {
            this.$bus.$emit(`initPayButton_${payButtonData.busKey}`, {
              ...payButtonData,
              location: location,
              dataId: dataId,
              template_id: this.getCurrentStep.template_id,
              entity_id: params.entity_id,
            });
          }
          this.userEntityDataId = dataId;
          if (sendForm) {
            this.updateTempleteCompletionStatus(dataId, sendForm);
          }
          newCreated = true;
          this.updateStepEntityData({
            entityDataId: dataId,
            index: this.currentStepIndex,
          });
          await this.updateRelationData(dataId);
        }
        if (this.isExecute && this.getCurrentStep?.templateInfo?.show_success_notification_after_save) {
          this.$notify.success({
            title: "Success",
            message: "Data updated successfully",
          });
        }
        if (
          this.checkIsRepeatable &&
          checkRepeatable &&
          this.repeatableData.length < this.getMaxNoOfRecords
        ) {
          this.currentTemplateData = {};
          this.addingNewData = true;
          this.refresh = true;
          setTimeout(() => {
            this.refresh = false;
            this.entityLoading = false;
          });
          return;
        }
        if (
          this.getAllSteps.length ==
          parseInt(this.currentTemplateIndex) + 1
        ) {
          this.entityLoading = false;
          this.currentTemplateData = {};
          this.relationshipData = {};
          if (this.fromSenderSide) {
            bus.$emit("next-step", {
              finish:
                parseInt(this.currentStepIndex) + 1 >=
                this.getFormBuilder?.steps.length
                  ? true
                  : false,
            });
          } else {
            this.$emit("submitEntityData", {
              entityDataId: newCreated
                ? this.getUserTemplateDataUpdateStatus._id
                : this.entityDataId,
              entity_id: this.step.entity._id || this.step.entity,
            });
          }
          // this.userEntityDataId = "";
          return;
        }
        if (this.fromSenderSide) {
          this.gotoNextTemplate();
        } else {
          this.currentTemplateIndex++;
          this.currentRepeatableIndex = -1;
          this.repeatableData = [];
          this.refresh = true;
          setTimeout(() => {
            this.refresh = false;
            this.entityLoading = false;
          });
          this.fetchEntityData(this.userEntityDataId);
          this.setCurrentTemplateData();
        }
      } else {
        let msg = "Error while updating data";
        if (this.getUserTemplateDataUpdateErrors.message) {
          msg = this.getUserTemplateDataUpdateErrors.message;
        }
        this.$notify.error({
          title: "Error",
          message: msg,
        });
        this.entityLoading = false;
      }
    },
    async updateTempleteCompletionStatus(entityDataId, sentFlow) {
      let params = {
        entity_data_id: this.entityDataId || entityDataId,
        step_type: "ENTITY",
        entity_id: this.step.entity._id || this.step.entity,
        formbuilder_step_id: this.getCurrentFormStep._id,
        formbuilder_data_id: this.$route.params?.formbuilder_data_id,
        completion_status: true,
      };
      await this.$store.dispatch(
        "formbuilderData/updateStepCompletionStatus",
        params
      );
      if (sentFlow) {
        bus.$emit("send-formbuilder", {});
      }
    },
    async updateRelationData(id) {
      if (
        this.currentStepRelationship &&
        this.getAuthenticatedUser?.account_data_id
      ) {
        let data;
        if (this.currentStepRelationship.owner_type == "PARENT") {
          data = {
            parent_entity_id: this.currentStepRelationship?.parent_entity_id,
            child_entity_id: this.currentStepRelationship?.child_entity_id,
            parent_entity_data_id: this.getAuthenticatedUser.account_data_id,
            child_entity_data_ids: [id],
          };
        } else {
          if (this.currentStepRelationship?.parent_relation_type == "MANY") {
            data = {
              parent_entity_id: this.currentStepRelationship?.parent_entity_id,
              child_entity_id: this.currentStepRelationship?.child_entity_id,
              parent_entity_data_id: id,
              child_entity_data_ids: [
                this.getAuthenticatedUser.account_data_id,
              ],
            };
          } else {
            data = {
              parent_entity_id: this.currentStepRelationship.parent_entity_id,
              child_entity_id: this.currentStepRelationship.child_entity_id,
              parent_entity_data_id: id,
              child_entity_data_ids: [
                this.getAuthenticatedUser.account_data_id,
              ],
            };
          }

          if (
            this.currentStepRelationship?.representation == "FIELD" &&
            this.unAssignedFieldDetails
          ) {
            data.assign_field_data = [];
            this.unAssignedFieldDetails.forEach((field) => {
              data.assign_field_data.push({
                ...field,
                ...{
                  entity_data_id: this.getAuthenticatedUser.account_data_id,
                },
              });
            });
          }
        }
        await this.$store.dispatch(
          "entityRelationships/assignEntityRelationshipData",
          data
        );
        if (this.getEntityRelationshipDataAssignStatus) {
          if (this.getFormBuilder.entity_id) {
            let params = {
              parent_entity_id: this.getFormBuilder.entity_id,
              parent_entity_data_id: this.getAuthenticatedUser?.account_data_id,
            };

            await this.$store.dispatch(
              "entityRelationships/fetchEntityAllRelationshipData",
              params
            );
          }
        }
      }
      //check for field relationships
      if (
        this.getEntityAllRelationshipData &&
        this.getEntityAllRelationshipData.length
      ) {
        let fieldTypeRelationfields = this.getEntityAllRelationshipData.filter(
          (e) => e.representation == "FIELD"
        );
        if (fieldTypeRelationfields && fieldTypeRelationfields.length) {
          let relationshipsData = [
            ...(this.getEntityAllRelationshipData || []),
          ];

          relationshipsData = relationshipsData.map((e) => {
            if (e.parent_entity_id == this.currentEntity?._id) {
              e.owner_type = "PARENT";
              e.relationship_title = e.child_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.child_entity_id;
            } else {
              e.owner_type = "CHILD";
              e.relationship_title = e.parent_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.parent_entity_id;
            }
            e.parent_entity_data_id = this.entityDataId;
            return e;
          });

          relationshipsData = relationshipsData.filter((e) => {
            if (e.owner_type == "CHILD" && !e.is_bidirectional) {
              return false;
            } else {
              return true;
            }
          });
          let allFields = this.getAllFieldsFormEntity(this.currentEntity);
          let fieldsHasRelationships = allFields.filter((field) => {
            let relationship = relationshipsData.find(
              (x) =>
                x.field_id == field._id && x.child_entity_id == field.entity_id
            );

            return relationship &&
              this.getCurrentStep.template_id == field.template_id
              ? true
              : false;
          });
          if (fieldsHasRelationships && fieldsHasRelationships.length) {
            let toAssignRelationshipsData = this.prepareRelationshipData(
              allFields,
              relationshipsData,
              this.entityDataId,
              this.getAuthenticatedUser &&
                this.getAuthenticatedUser.account_data_id
                ? this.getAuthenticatedUser.account_data_id
                : null,
              this.currentTemplateData
            );
            if (toAssignRelationshipsData.length) {
              await this.$store.dispatch(
                "entityRelationships/assignFieldsEntityRelationshipData",
                toAssignRelationshipsData
              );
            }
          }
        }
      }
    },
    getAllFieldsFormEntity(entity) {
      if (entity && entity.templates && entity.templates.length) {
        let result = [];
        entity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            result = [...result, ...this.getTemplateFields(e.templateInfo)];
          }
        });
        return result;
      }
      return [];
    },
    async gotoPrevEntityTemplate() {
      this.currentTemplateIndex--;
      this.refresh = true;
      setTimeout(() => {
        this.refresh = false;
      });
      await this.setCurrentTemplateData();
    },
    saveFormbuilderTemplateData() {
      // this.$emit("saveFormbuilderTemplateData");
    },
    allFieldsCompleted() {
      // this.$emit("allFieldsCompleted");
    },
    async getNecessaryInfo() {
      this.entityLoading = true;
      let allPromises = [
        this.$store.dispatch("entities/fetchEntityByIdForEntityField", {
          entity_id: this.step.entity._id || this.step.entity,
        }),
      ];
      let id = await this.entityDataId;
      if (id) {
        this.userEntityDataId = id;
        allPromises.push(
          this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
            id: this.userEntityDataId,
            includeTemplateData: true,
          })
        );
      } else {
        // setEntityPrimaryData;
        this.$store.commit(
          "entities/setEntityPrimaryData",
          {},
          {
            root: true,
          }
        );
      }
      await Promise.all(allPromises);
      if (this.getEntityById) {
        this.currentEntity = this.getEntityById;
        if (this.step?.settings?.selectedTemplates) {
          this.currentEntityTemplates = this.currentEntity?.templates.filter(
            (e) =>
              this.step?.settings?.selectedTemplates.indexOf(e.template_id) !=
              -1
          );
        } else {
          this.currentEntityTemplates = [
            ...[],
            ...this.currentEntity?.templates,
          ];
        }
        this.setEntityRelationshipData();
      }
      this.entityData = {};
      if (
        this.getEntityPrimaryData &&
        (this.getEntityPrimaryData.entity_id == this.step.entity._id ||
          this.getEntityPrimaryData.entity_id == this.step.entity)
      ) {
        this.entityData = this.getEntityPrimaryData;
        if (this.entityData.templateData) {
          this.setCurrentTemplateData();
        }
      }
      this.entityLoading = false;
    },
    setEntityRelationshipData() {
      if (this.step?.settings?.applyRelationship) {
        let currentStepRelationship = (
          this.getEntityAllRelationshipData || []
        ).find(
          (e) =>
            e.relationship_id == this.step?.entity?._id ||
            e.relationship_id == this.step.entity
        );
        if (
          currentStepRelationship?.owner_type == "CHILD" &&
          currentStepRelationship?.representation == "FIELD"
        ) {
          if (this.getCurrentStep?.templateInfo?.sections[0]?.fields) {
            this.getCurrentStep?.templateInfo?.sections[0]?.fields.forEach(
              (element) => {
                if (
                  element?.inputType == "ENTITY" &&
                  element?.entity_id == this.getFormBuilder?.entity_id &&
                  this.getAuthenticatedUser?.account_data_id
                ) {
                  this.unAssignedFieldDetails = [
                    ...this.unAssignedFieldDetails,
                    ...[
                      {
                        key: element.key,
                        template_id: this.getCurrentStep.template_id,
                        value: this.getAuthenticatedUser?.account_data_id,
                      },
                    ],
                  ];
                  this.currentTemplateData = {
                    ...{},
                    ...{
                      [element.key]: this.getAuthenticatedUser.account_data_id,
                    },
                  };
                }
              }
            );
          }
        }
      }
    },
    setCurrentTemplateData() {
      let currentTemplateData = (this.entityData?.templateData || []).find(
        (e) =>
          e &&
          e.template_id &&
          this.getCurrentStep &&
          e.template_id == this.getCurrentStep.template_id
      );
      this.currentTemplateData = {};
      this.getCurrentTemplateFields.forEach((el) => {
        if (el.input_type == "CHECKBOX_GROUP") {
          this.$set(this.currentTemplateData, el.key, []);
        }
      });
      
      if (currentTemplateData) {
        this.setTemplateData(currentTemplateData);
      }
    },
    setTemplateData(data) {
      if (this.checkIsRepeatable) {
        if (
          data.template_data &&
          Object.keys(data.template_data) &&
          Object.keys(data.template_data)[0]
        ) {
          this.repeatableData =
            data.template_data[Object.keys(data.template_data)[0]];
          if (this.repeatableData.length) {
            this.currentRepeatableIndex = 0;
            this.currentTemplateData =
              this.repeatableData[this.currentRepeatableIndex];
          }
          // else {
          //   this.currentRepeatableIndex = -1;
          //   this.addingNewData = true;
          // }
        }
      } else {
        this.currentTemplateData = { ...{}, ...data.template_data };
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-template-preview{
  width: 79vw; 
  margin-left:20px
}
.document-status {
  position: fixed;
  bottom: 0px;
  background: white;
  border: #ccc 1px solid;
  border-radius: 10px;
  height: 70px;
  width: 100%;
  @media (min-width: 1300px) {
    &.builder-default {
      width: 1200px;
    }
  }
  @media (min-width: 320px) {
    max-width: 1200px;
  }
  .template-name {
    float: left;
    font-weight: bold;
  }
}
.data-actions {
  float: right;
}
.repeatable-status {
  background: white;
  border: #ccc 1px solid;
  // border-radius: 10px;
  height: 50px;
  width: 100%;
}

.form-builder-template-view{
  height: 80vh;
  max-height: 80vh;
}
</style>
